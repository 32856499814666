import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import {
  activityUserSummary,
  activityUserPaginate,
  dataTryOutSummary,
  dataTryOutPaginate,
  dataTryOutIdPaginate,
  userMooridPaginate,
  userAdminSummary,
  userAdminProvincePaginate,
  userAdminProvinceIdPaginate,
  userAdminDistrictPaginate,
  userAdminDistrictIdPaginate,
  financePaginate,
  financeSummary,
  financeGraph,
} from 'services/dashboard'
import actions from './actions'

export function* loading() {
  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_ACTIVITY_USER_SUMMARY_FROM_TO() {
  yield loading()
  const { activityUserSummary: data } = yield select(state => state.dashboard)
  const success = yield call(activityUserSummary, data)
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        activityUserSummary: {
          data: success,
          fromTo: data.fromTo,
        },
      },
    })
  }
  yield loaded()
}

export function* GET_ACTIVITY_USER_PAGINATE() {
  yield loading()
  const { activityUserPaginate: data, activityUserSummary: fromTo } = yield select(
    state => state.dashboard,
  )
  const success = yield call(activityUserPaginate, { ...data.paginate, from: fromTo.fromTo[0] })
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        activityUserPaginate: {
          data: success.data,
          paginate: {
            ...data.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_TRY_OUT_SUMMARY() {
  yield loading()
  const success = yield call(dataTryOutSummary)
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        dataTryOutSummary: success,
      },
    })
  }
  yield loaded()
}

export function* GET_TRY_OUT_PAGINATE() {
  yield loading()
  const { dataTryOutPaginate: data } = yield select(state => state.dashboard)
  const success = yield call(dataTryOutPaginate, data.paginate)
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        dataTryOutPaginate: {
          data: success.data,
          paginate: {
            ...data.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_TRY_OUT_ID_PAGINATE({ payload }) {
  yield loading()
  const { id } = payload
  const { dataTryOutIdPaginate: data } = yield select(state => state.dashboard)
  const success = yield call(dataTryOutIdPaginate, {
    ...data.paginate,
    id,
  })
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        dataTryOutIdPaginate: {
          data: success.data,
          paginate: {
            ...data.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_USER_ADMIN_PROVINCE_PAGINATE() {
  yield loading()
  const { userAdminProvincePaginate: data, activityUserSummary: fromTo } = yield select(
    state => state.dashboard,
  )
  const success = yield call(userAdminProvincePaginate, {
    ...data.paginate,
    from: fromTo.fromTo[0],
    to: fromTo.fromTo[1],
  })
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        userAdminProvincePaginate: {
          data: success.data,
          paginate: {
            ...data.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_USER_ADMIN_DISTRICT_PAGINATE() {
  yield loading()
  const { userAdminDistrictPaginate: data, activityUserSummary: fromTo } = yield select(
    state => state.dashboard,
  )
  const success = yield call(userAdminDistrictPaginate, {
    ...data.paginate,
    from: fromTo.fromTo[0],
    to: fromTo.fromTo[1],
  })
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        userAdminDistrictPaginate: {
          data: success.data,
          paginate: {
            ...data.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_USER_MOORID_PAGINATE() {
  yield loading()
  const { userMooridPaginate: data, activityUserSummary: fromTo } = yield select(
    state => state.dashboard,
  )
  const success = yield call(userMooridPaginate, {
    ...data.paginate,
    from: fromTo.fromTo[0],
    to: fromTo.fromTo[1],
  })
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        userMooridPaginate: {
          data: success.data,
          paginate: {
            ...data.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_USER_ADMIN_DISTRICT_ID_PAGINATE({ payload }) {
  yield loading()
  const { id } = payload
  const { userAdminDistrictIdPaginate: data, activityUserSummary: fromTo } = yield select(
    state => state.dashboard,
  )
  const success = yield call(userAdminDistrictIdPaginate, {
    ...data.paginate,
    from: fromTo.fromTo[0],
    to: fromTo.fromTo[1],
    id,
  })
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        userAdminDistrictIdPaginate: {
          data: success.data,
          paginate: {
            ...data.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_USER_ADMIN_PROVINCE_ID_PAGINATE({ payload }) {
  yield loading()
  const { id } = payload
  const { userAdminProvinceIdPaginate: data, activityUserSummary: fromTo } = yield select(
    state => state.dashboard,
  )
  const success = yield call(userAdminProvinceIdPaginate, {
    ...data.paginate,
    from: fromTo.fromTo[0],
    to: fromTo.fromTo[1],
    id,
  })
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        userAdminProvinceIdPaginate: {
          data: success.data,
          paginate: {
            ...data.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_FINANCE_PAGINATE() {
  yield loading()
  const { financePaginate: data, activityUserSummary: fromTo } = yield select(
    state => state.dashboard,
  )
  const success = yield call(financePaginate, {
    ...data.paginate,
    from: fromTo.fromTo[0],
    to: fromTo.fromTo[1],
  })
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        financePaginate: {
          data: success.data,
          paginate: {
            ...data.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_FINANCE_SUMMARY() {
  yield loading()
  const { activityUserSummary: fromTo } = yield select(state => state.dashboard)
  const success = yield call(financeSummary, {
    from: fromTo.fromTo[0],
    to: fromTo.fromTo[1],
  })
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        financeSummary: success,
      },
    })
  }
  yield loaded()
}

export function* GET_FINANCE_GRAPH() {
  yield loading()
  const { activityUserSummary: fromTo } = yield select(state => state.dashboard)
  const success = yield call(financeGraph, {
    from: fromTo.fromTo[0],
    to: fromTo.fromTo[1],
  })
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        financeGraph: success.data,
      },
    })
  }
  yield loaded()
}

export function* GET_USER_ADMIN_SUMMARY() {
  yield loading()
  const { activityUserSummary: fromTo } = yield select(state => state.dashboard)
  const success = yield call(userAdminSummary, {
    from: fromTo.fromTo[0],
    to: fromTo.fromTo[1],
  })
  if (success) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        userAdminSummary: success,
      },
    })
  }
  yield loaded()
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ACTIVITY_USER_SUMMARY_FROM_TO, GET_ACTIVITY_USER_SUMMARY_FROM_TO),
    takeEvery(actions.GET_ACTIVITY_USER_PAGINATE, GET_ACTIVITY_USER_PAGINATE),
    takeEvery(actions.GET_TRY_OUT_SUMMARY, GET_TRY_OUT_SUMMARY),
    takeEvery(actions.GET_TRY_OUT_PAGINATE, GET_TRY_OUT_PAGINATE),
    takeEvery(actions.GET_TRY_OUT_ID_PAGINATE, GET_TRY_OUT_ID_PAGINATE),
    takeEvery(actions.GET_USER_ADMIN_SUMMARY, GET_USER_ADMIN_SUMMARY),
    takeEvery(actions.GET_USER_ADMIN_PROVINCE_PAGINATE, GET_USER_ADMIN_PROVINCE_PAGINATE),
    takeEvery(actions.GET_USER_ADMIN_DISTRICT_PAGINATE, GET_USER_ADMIN_DISTRICT_PAGINATE),
    takeEvery(actions.GET_USER_ADMIN_PROVINCE_ID_PAGINATE, GET_USER_ADMIN_PROVINCE_ID_PAGINATE),
    takeEvery(actions.GET_USER_ADMIN_DISTRICT_ID_PAGINATE, GET_USER_ADMIN_DISTRICT_ID_PAGINATE),
    takeEvery(actions.GET_USER_MOORID_PAGINATE, GET_USER_MOORID_PAGINATE),
    takeEvery(actions.GET_FINANCE_PAGINATE, GET_FINANCE_PAGINATE),
    takeEvery(actions.GET_FINANCE_SUMMARY, GET_FINANCE_SUMMARY),
    takeEvery(actions.GET_FINANCE_GRAPH, GET_FINANCE_GRAPH),
  ])
}

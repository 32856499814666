export default function faqCategory() {
  return {
    forms: {
      item: [
        {
          name: 'id',
          hidden: true,
          rules: [{ required: false }],
          children: {},
        },
        {
          label: 'Order Kategori',
          name: 'cms_faq_category_order',
          rules: [{ required: true }],
          className: 'col-12',
          children: {
            component: 'input',
            placeholder: 'Urutan Kategori',
          },
        },
        {
          label: 'Nama Kategori',
          name: 'cms_faq_category_name',
          rules: [{ required: true }],
          className: 'col-12',
          children: {
            component: 'input',
            placeholder: 'Cth : pendaftaran',
          },
        },
      ],
      fields: [
        {
          name: ['id'],
        },
        {
          name: ['cms_faq_category_name'],
        },
      ],
    },
  }
}

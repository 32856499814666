const actions = {
  SET_STATE: 'referral/SET_STATE',
  GET_PAGINATE: 'referral/GET_PAGINATE',
  GET_BALANCE: 'referral/GET_BALANCE',
  GET_SUMMARY: 'referral/GET_SUMMARY',
  GET_PAGINATE_DRAW: 'referral/GET_PAGINATE_DRAW',
  GET_PAGINATE_SETTING: 'referral/GET_PAGINATE_SETTING',
  GET_MENU_DRAW: 'referral/GET_MENU_DRAW',
  GET_BONUS_AMOUNT: 'referral/GET_BONUS_AMOUNT',
  GET_BONUS_PERCENT: 'referral/GET_BONUS_PERCENT',
}

export default actions

import apiClient from 'services/axios'
import response from 'services/response'

export async function couponPaginate(payload) {
  const { perPage, currentPage, sort, search } = payload
  let res = null
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(`master/coupon/${perPage}/${currentPage}/${sort}${params}`)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function couponListAll() {
  let res = null
  try {
    res = await apiClient.get(`master/coupon/list-all`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function couponCreate(payload) {
  let res = null
  try {
    res = await apiClient.post(`master/coupon`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function couponUpdate(id, data) {
  let res = null
  try {
    res = await apiClient.post(`master/coupon/${id}`, data)
    return res.data
  } catch (error) {
    return false
  }
}

export async function couponFindById(id) {
  let res = null
  try {
    res = await apiClient.get(`master/coupon/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function couponDelete(id) {
  let res = null
  try {
    res = await apiClient.delete(`master/coupon/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'campus_name',
    },
  },
  listAll: [],
  id: '',
  data: '',
  forms: {
    item: [
      {
        name: 'id',
        hidden: true,
        rules: [{ required: false }],
        children: '',
      },
      {
        label: 'Kampus / Institusi',
        name: 'campus_name',
        rules: [{ required: true }],
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Ketik nama Kampus',
          className: 'form-control',
        },
      },
      
    ],
    fields: [
      {
        name: ['id'],
      },
      {
        name: ['campus_name'],
      },
      {
        name: ['campusStudyPrograms'],
      },
      {
        name: ['SOSHUM'],
      },
      {
        name: ['SAINTEK'],
      },
    ],
  },
  loading: false,
}

export default function campusReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const actions = {
  SET_STATE: 'dashboard/SET_STATE',
  GET_ACTIVITY_USER_SUMMARY_FROM_TO: 'dashboard/GET_ACTIVITY_USER_SUMMARY_FROM_TO',
  GET_ACTIVITY_USER_PAGINATE: 'dashboard/GET_ACTIVITY_USER_PAGINATE',
  GET_USER_MOORID_PAGINATE: 'dashboard/GET_USER_MOORID_PAGINATE',
  GET_USER_ADMIN_SUMMARY: 'dashboard/GET_USER_ADMIN_SUMMARY',
  GET_USER_ADMIN_PROVINCE_PAGINATE: 'dashboard/GET_USER_ADMIN_PROVINCE_PAGINATE',
  GET_USER_ADMIN_PROVINCE_ID_PAGINATE: 'dashboard/GET_USER_ADMIN_PROVINCE_ID_PAGINATE',
  GET_USER_ADMIN_DISTRICT_PAGINATE: 'dashboard/GET_USER_ADMIN_DISTRICT_PAGINATE',
  GET_USER_ADMIN_DISTRICT_ID_PAGINATE: 'dashboard/GET_USER_ADMIN_DISTRICT_ID_PAGINATE',
  GET_TRY_OUT_SUMMARY: 'dashboard/GET_TRY_OUT_SUMMARY',
  GET_TRY_OUT_PAGINATE: 'dashboard/GET_TRY_OUT_PAGINATE',
  GET_TRY_OUT_ID_PAGINATE: 'dashboard/GET_TRY_OUT_ID_PAGINATE',
  GET_FINANCE_PAGINATE: 'dashboard/GET_FINANCE_PAGINATE',
  GET_FINANCE_SUMMARY: 'dashboard/GET_FINANCE_SUMMARY',
  GET_FINANCE_GRAPH: 'dashboard/GET_FINANCE_GRAPH',
}

export default actions

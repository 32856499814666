import apiClient from 'services/axios'
import response from 'services/response'

export async function topupPaginate(payload) {
  const { perPage, currentPage, sort, search, status, date } = payload
  let res = null
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(
      `finance/koin/list-topup/${status}/${perPage}/${currentPage}/${sort}/${date ||
        'all'}${params}`,
    )
    return response({ ...res.data, search, sort, status, date })
  } catch (error) {
    return error
  }
}

export async function topupListAll() {
  let res = false
  try {
    res = await apiClient.get(`finance/koin/summary`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function topupCreate(payload) {
  let res = null
  try {
    res = await apiClient.post(`master/topup`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function topupUpdate(id, data) {
  let res = null
  try {
    res = await apiClient.post(`master/topup/${id}`, data)
    return res.data
  } catch (error) {
    return false
  }
}

export async function topupFindById(id) {
  let res = null
  try {
    res = await apiClient.get(`master/topup/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function topupDelete(id) {
  let res = null
  try {
    res = await apiClient.delete(`master/topup/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

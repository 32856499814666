import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'voucher_name',
    },
  },
  listAll: [],
  id: '',
  data: {},
  detail:{
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'voucher_code',
    },
  },
  forms: {
    item: [
      {
        name: 'id',
        hidden: true,
        rules: [{ required: false }],
        children: {},
      },
      {
        label: 'Nama Voucher',
        name: 'voucher_name',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'input',
          placeholder: 'Ketik Nama Voucher',
        },
      },
      {
        label: 'Jumlah Voucher',
        name: 'voucher_qty',
        rules: [{ required: true },
        ({ getFieldValue,setFieldsValue }) => ({
          validator() {
            setFieldsValue({budget:+getFieldValue('voucher_amount') * +getFieldValue('voucher_qty')})
            return Promise.resolve()
          },
        })],
        dependencies:['voucher_amount'],
        className: 'col-6',
        children: {
          component: 'input',
          placeholder: 'Ketik Jumlah Voucher',
        },
      },
      {
        label: 'Nominal Voucher',
        name: 'voucher_amount',
        rules: [{ required: true },
        ({ getFieldValue,setFieldsValue }) => ({
          validator() {
            setFieldsValue({budget:+getFieldValue('voucher_amount') * +getFieldValue('voucher_qty')})
            return Promise.resolve()
          },
        })],
        dependencies:['voucher_qty'],
        className: 'col-6',
        children: {
          component: 'input',
          placeholder: 'Ketik Nominal Voucher',
          addonBefore: 'Rp',
        },
      },
      {
        label: 'Perkiraan Budget Voucher',
        className: 'col-12',
        help: 'Hasil dari perkalian jumlah dan nominal voucher',
        name:'budget',
        children: {
          component: 'input',
          placeholder: 'Rp ',
          disable:1
        },
      },
    ],
    fields: [
      { name: ['id'] },
      { name: ['voucher_name'] },
      { name: ['voucher_qty'] },
      { name: ['voucher_amount'] },
    ],
  },
  loading: false,
  summary: {
    voucher_amount_supply: 0,
    voucher_amount_left: 0,
    voucher_qty: 0,
    voucher_qty_left: 0,
    voucher_amount_used: 0,
    voucher_qty_used: 0,
  },
  summaryLoading: false,
}

export default function voucherReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

import apiClient from 'services/axios'
import response from 'services/response'

export async function bundlePaginate(payload) {
  const { perPage, currentPage, sort, search, bundleSold } = payload
  let res = null
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(
      `master/bundle/${bundleSold}/${perPage}/${currentPage}/${sort}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function bundleListAll() {
  let res = null
  try {
    res = await apiClient.get(`master/bundle/list-all`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function bundleCreate(payload) {
  let res = null
  try {
    res = await apiClient.post(`master/bundle`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function bundleUpdate(id, data) {
  let res = null
  try {
    res = await apiClient.post(`master/bundle/${id}`, data)
    return res.data
  } catch (error) {
    return false
  }
}

export async function bundleFindById(id) {
  let res = null
  try {
    res = await apiClient.get(`master/bundle/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function bundleDelete(id) {
  let res = null
  try {
    res = await apiClient.delete(`master/bundle/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function bundleSoldPaginate(payload) {
  const { perPage, currentPage, sort, search, status, bundleId, date } = payload
  const params = search ? `/${search}` : ''
  try {
    const { data } = await apiClient.get(
      `finance/bundle-order/list-purchase/${status}/${perPage}/${currentPage}/${sort}/${date ||
        'all'}/${bundleId}${params}`,
    )
    return response({ ...data, search, sort })
  } catch (error) {
    return false
  }
}

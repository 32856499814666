import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  referralPaginate,
  referralPaginateDraw,
  referralBalance,
  referralSummary,
  referralMenu,
  referralSetting,
  referralBonusAmount,
  referralBonusPercent,
} from 'services/referral'
import actions from './actions'

export function* GET_PAGINATE() {
  const { listTable } = yield select(state => state.referral)
  yield loading()
  const success = yield call(referralPaginate, listTable.paginate)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        listTable: {
          data: success.data,
          paginate: {
            ...listTable.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_PAGINATE_DRAW() {
  yield loading()
  const { listPaginateDraw } = yield select(state => state.referral)
  const success = yield call(referralPaginateDraw, listPaginateDraw.paginate)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        listPaginateDraw: {
          data: success.data,
          paginate: {
            ...listPaginateDraw.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_PAGINATE_SETTING() {
  yield loading()
  const { listPaginateSetting } = yield select(state => state.referral)
  const success = yield call(referralSetting, listPaginateSetting.paginate)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        listPaginateSetting: {
          data: success.data,
          paginate: {
            ...listPaginateSetting.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_BALANCE() {
  yield loading()
  const success = yield call(referralBalance)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        balance: success,
      },
    })
  }
  yield loaded()
}

export function* loading() {
  yield put({
    type: 'referral/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'referral/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_SUMMARY() {
  yield loading()
  const success = yield call(referralSummary)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        summary: success,
      },
    })
  }
  yield loaded()
}

export function* GET_MENU_DRAW() {
  yield loading()
  const success = yield call(referralMenu)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        menu: success,
      },
    })
  }
  yield loaded()
}

export function* GET_BONUS_PERCENT() {
  yield loading()
  const success = yield call(referralBonusPercent)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        bonusPercent: success,
      },
    })
  }
  yield loaded()
}
export function* GET_BONUS_AMOUNT() {
  yield loading()
  const success = yield call(referralBonusAmount)
  if (success) {
    yield put({
      type: 'referral/SET_STATE',
      payload: {
        bonusAmount: success,
      },
    })
  }
  yield loaded()
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
    takeEvery(actions.GET_BALANCE, GET_BALANCE),
    takeEvery(actions.GET_SUMMARY, GET_SUMMARY),
    takeEvery(actions.GET_PAGINATE_DRAW, GET_PAGINATE_DRAW),
    takeEvery(actions.GET_PAGINATE_SETTING, GET_PAGINATE_SETTING),
    takeEvery(actions.GET_MENU_DRAW, GET_MENU_DRAW),
    takeEvery(actions.GET_BONUS_AMOUNT, GET_BONUS_AMOUNT),
    takeEvery(actions.GET_BONUS_PERCENT, GET_BONUS_PERCENT),
  ])
}

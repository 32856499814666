import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  bundlePaginate,
  bundleListAll,
  bundleSoldPaginate,
  // bundleCreate,
  // bundleUpdate,
  // bundleFindById,
  // bundleDelete,
} from 'services/bundle'
import actions from './actions'

export function* GET_PAGINATE() {
  const { listTable } = yield select(state => state.bundle)
  yield loading()
  const success = yield call(bundlePaginate, listTable.paginate)
  if (success) {
    yield put({
      type: 'bundle/SET_STATE',
      payload: {
        listTable: {
          data: success.data,
          paginate: listTable.paginate,
        },
      },
    })
  }
  yield loaded()
}

export function* GET_LIST_ALL() {
  const success = yield call(bundleListAll)
  if (success) {
    yield put({
      type: 'bundle/SET_STATE',
      payload: {
        listAll: success,
      },
    })
  }
}

export function* loading() {
  yield put({
    type: 'bundle/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'bundle/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_BUNDLE_SOLD({ payload }) {
  yield loading()
  const { bundleId } = payload
  const { bundleSoldPaginate: data } = yield select(state => state.bundle)

  const success = yield call(bundleSoldPaginate, { ...data.paginate, bundleId })
  if (success) {
    yield put({
      type: 'bundle/SET_STATE',
      payload: {
        bundleSoldPaginate: {
          data: success.data,
          paginate: {
            bundleId,
            ...data.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
    takeEvery(actions.GET_LIST_ALL, GET_LIST_ALL),
    takeEvery(actions.GET_BUNDLE_SOLD, GET_BUNDLE_SOLD),
  ])
}

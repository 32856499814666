const actions = {
  SET_STATE: 'submaterial/SET_STATE',
  GET_PAGINATE: 'submaterial/GET_PAGINATE',
  GET_LIST_ALL: 'submaterial/GET_LIST_ALL',
  GET_BY_ID: 'submaterial/GET_BY_ID',
  POST_CREATE: 'submaterial/POST_CREATE',
  POST_UPDATE: 'submaterial/POST_UPDATE',
  DELETE_DATA: 'submaterial/DELETE_DATA',
}

export default actions

import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import setting from './setting/sagas'
import submaterial from './submaterial/sagas'
import material from './material/sagas'
import category from './category/sagas'
import campus from './campus/sagas'
import packet from './packet/sagas'
import excercise from './excercise/sagas'
import answer from './answer/sagas'
import coupon from './coupon/sagas'
import voucher from './voucher/sagas'
import topup from './topup/sagas'
import bundle from './bundle/sagas'
import cms from './cms/sagas'
import users from './users/sagas'
import referral from './referral/sagas'
import dashboard from './dashboard/sagas'
import provinceDistrict from './provinceDistrict/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    setting(),
    settings(),
    submaterial(),
    material(),
    category(),
    campus(),
    packet(),
    excercise(),
    answer(),
    coupon(),
    voucher(),
    topup(),
    bundle(),
    cms(),
    users(),
    referral(),
    dashboard(),
    provinceDistrict(),
  ])
}

import apiClient from 'services/axios'
import response from 'services/response'

export async function referralPaginate(payload) {
  const { perPage, currentPage, sort, search } = payload
  const params = search ? `/${search}` : ''
  try {
    const res = await apiClient.get(
      `finance/afiliate-history-topup-admin/${perPage}/${currentPage}/${sort}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function referralPaginateDraw(payload) {
  const { perPage, currentPage, sort, search, status, date } = payload
  const params = search ? `/${search}` : ''
  try {
    const res = await apiClient.get(
      `finance/afiliate-history-draw/${perPage}/${currentPage}/${sort}/${date ||
        'all'}/${status}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function referralBonusAmount() {
  try {
    const res = await apiClient.get(`master/setting/view-by-key/Saldo Bonus`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function referralBonusPercent() {
  try {
    const res = await apiClient.get(`master/setting/view-by-key/Persen Referal`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function referralSetting(payload) {
  const { perPage, currentPage, sort, search, date } = payload
  const params = search ? `/${search}` : ''
  try {
    const res = await apiClient.get(
      `finance/afiliate-history-topup/${perPage}/${currentPage}/${sort}/${date || 'all'}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function referralBalance() {
  let res = null
  try {
    res = await apiClient.get(`finance/afiliate-history/balance`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function referralSummary() {
  try {
    const res = await apiClient.get(`finance/dashboard-afiliate/summary-admin`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function referralMenu() {
  try {
    const res = await apiClient.get(`finance/afiliate-history-draw/menu`)
    return res.data.success
  } catch (error) {
    return false
  }
}

/* eslint-disable camelcase */
import { all, takeEvery, put, call, select, fork } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as jwt from 'services/jwt'
import { onMessageListener, requestFirebaseNotificationPermission } from 'services/firebase'
import actions from './actions'

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
    profile: jwt.profile,
    passwordEmail: jwt.passwordEmail,
    avatar: jwt.avatar,
  },
}

export function* LOGIN({ payload }) {
  const { username, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(mapAuthProviders.jwt.login, username, password)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/')
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
      payload: success,
    })
    yield history.push('/')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(mapAuthProviders.jwt.currentAccount)
  if (response) {
    const {
      staff,
      is_aktif,
      role: { role_display_name },
    } = response
    const {
      id,
      username,
      nama_lengkap,
      email,
      school_from,
      year_graduation,
      birthday_place,
      birthday_date,
      phone_number,
      instagram_username,
      avatar_url,
      afiliate_code,
      afiliate_code_referal,
      province,
      district,
      is_profile_empty,
    } = staff
    if (process.env.REACT_APP_FIREBASE === 'true') {
      const p = yield call(requestFirebaseNotificationPermission)
      if (p) {
        yield fork(onMessageListener)
      }
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        username,
        nama_lengkap,
        email,
        school_from,
        year_graduation,
        birthday_place,
        birthday_date,
        phone_number,
        instagram_username,
        avatar_url,
        afiliate_code,
        afiliate_code_referal,
        role: role_display_name,
        is_aktif,
        authorized: true,
        province,
        district,
        is_profile_empty,
      },
    })
    yield put({
      type: 'topup/GET_BALANCE',
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* PASSWORD_EMAIL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      ...payload,
    },
  })
  const success = yield call(mapAuthProviders.jwt.passwordEmail, { payload })
  if (success) {
    const user = yield select(state => state.user)
    yield put({
      type: 'user/SET_STATE',
      payload: {
        ...user,
        ...success,
      },
    })
    notification.success({ message: 'Data Berhasil Di Update' })
  } else {
    notification.error({ message: 'Gagal Mengupdate Data' })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* PROFILE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      ...payload,
    },
  })

  const success = yield call(mapAuthProviders.jwt.profile, payload)
  if (success) {
    const user = yield select(state => state.user)
    yield put({
      type: 'user/SET_STATE',
      payload: {
        ...user,
        ...success,
      },
    })
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    notification.success({ message: 'Data Berhasil Di Update' })
  } else {
    notification.error({ message: 'Gagal Mengupdate Data' })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* AVATAR_UPLOAD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(mapAuthProviders.jwt.avatar, { payload })
  if (success) {
    yield put({
      type: 'user/SET_STATE',
      payload,
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      username: '',
      nama_lengkap: '',
      email: '',
      school_from: '',
      year_graduation: '',
      birthday_place: '',
      birthday_date: '',
      phone_number: '',
      instagram_username: '',
      avatar_url: '',
      afiliate_code: '',
      afiliate_code_referal: '',
      role: '',
      province: { id: '' },
      district: { id: '' },
      is_aktif: false,
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.PROFILE, PROFILE),
    takeEvery(actions.AVATAR_UPLOAD, AVATAR_UPLOAD),
    takeEvery(actions.PASSWORD_EMAIL, PASSWORD_EMAIL),
    LOAD_CURRENT_ACCOUNT(),
  ])
}

import { all, put, call, takeEvery } from 'redux-saga/effects'
import { fetchProvince, fetchDistrict } from 'services/provinceDistrict'
import actions from './actions'

export function* GET_PROVINCE() {
  yield loading()
  const success = yield call(fetchProvince)
  if (success) {
    yield put({
      type: 'provinceDistrict/SET_STATE',
      payload: {
        province: success,
      },
    })
  }
  yield loaded()
}

export function* GET_DISTRICT() {
  yield loading()
  const success = yield call(fetchDistrict)
  if (success) {
    yield put({
      type: 'provinceDistrict/SET_STATE',
      payload: {
        district: success,
      },
    })
  }
  yield loaded()
}

export function* loading() {
  yield put({
    type: 'provinceDistrict/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'provinceDistrict/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PROVINCE, GET_PROVINCE),
    takeEvery(actions.GET_DISTRICT, GET_DISTRICT),
  ])
}

import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import { settingPaginate, settingType } from 'services/setting'
import actions from './actions'

export function* GET_PAGINATE() {
  const { listTable } = yield select(state => state.setting)
  yield loading()
  const success = yield call(settingPaginate, listTable.paginate)
  if (success) {
    yield put({
      type: 'setting/SET_STATE',
      payload: {
        listTable: {
          data:success.data,
          paginate:listTable.paginate},
      },
    })
  }
  yield loaded()
}

export function* GET_TYPE(){
  yield loading()
  const success = yield call(settingType)
  if (success) {
    yield put({
      type: 'setting/SET_STATE',
      payload: {
        data: success,
      },
    })
  }
  yield loaded()
}

export function* loading() {
  yield put({
    type: 'setting/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'setting/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
    takeEvery(actions.GET_TYPE, GET_TYPE),
  ])
}

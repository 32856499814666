export default function ebookCategory() {
  return {
    forms: {
      item: [
        {
          name: 'id',
          hidden: true,
          rules: [{ required: false }],
          children: {},
        },
        {
          name: 'cms_ebook_category_name',
          rules: [{ required: true }],
          className: 'col-9',
          children: {
            component: 'input',
            placeholder: 'Cth : e-book best selling',
          },
        },
      ],
      fields: [
        {
          name: ['id'],
        },
        {
          name: ['cms_ebook_category_name'],
        },
      ],
    },
  }
}

import { message } from 'antd'
import apiClient from 'services/axios'
import store from 'store'

export async function login(username, password) {
  return apiClient
    .post('/auth/sign-in', {
      username,
      password,
      user_type: `${process.env.REACT_APP_USER_TYPE}`,
    })
    .then(response => {
      if (response) {
        const { token, role_display_name: rr } = response.data
        if (rr === 'siswa') {
          message.warning('Unauthorized Access This Page !')
          return false
        }
        if (token) {
          store.set('accessToken', token)
          store.set('user', JSON.stringify(response.data))
        }
        return response.data
      }
      return false
    })
    .catch(console.log)
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(console.log)
}

export async function currentAccount() {
  let res = false
  if (!store.get('accessToken')) return res
  try {
    res = await apiClient.get('/auth/view')
    return res.data
  } catch (error) {
    return false
  }
}

export async function logout() {
  return store.clearAll()
}

export async function passwordEmail({ payload }) {
  let res = null
  try {
    res = await apiClient.post('auth/update-password', payload)
    return res
  } catch (error) {
    return false
  }
}

export async function profile({ payload }) {
  let res = null
  try {
    res = await apiClient.post('auth/update', payload)
    return res.data.staff
  } catch (error) {
    return false
  }
}

export async function avatar({ payload }) {
  let res = false
  try {
    res = await apiClient.post('auth/update-avatar-url', payload)
    return res
  } catch (error) {
    return res
  }
}

import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import { cmsPaginate, cmsListAll } from 'services/cms'
import actions from './actions'
import * as forms from './forms'

export function* GET_PAGINATE({ payload }) {
  const { modules, suffix } = payload
  if (payload?.preload) {
    const { preload } = payload
    yield put({
      type: 'cms/GET_ALL',
      payload: preload,
    })
  }
  const scope = yield select(state => state.cms[modules])
  const { listTable } = scope
  yield loading({ modules, scope })
  const success = yield call(cmsPaginate, { ...listTable.paginate, suffix })
  if (success) {
    yield put({
      type: 'cms/SET_STATE',
      payload: {
        [modules]: {
          ...scope,
          listTable: {
            data: success.data,
            paginate: {
              ...listTable.paginate,
              ...success.paginate,
            },
          },
        },
      },
    })
  }
  yield loaded({
    modules,
    scope: {
      ...scope,
      listTable: {
        data: success.data || [],
        paginate: {
          ...listTable.paginate,
          ...(success.paginate || {}),
        },
      },
      ...forms[modules](),
    },
  })
}

export function* GET_ALL({ payload }) {
  const { modules, url } = payload
  const scope = yield select(state => state.cms[modules])
  yield loading({ modules, scope })
  const success = yield call(cmsListAll, url)
  if (success) {
    yield put({
      type: 'cms/SET_STATE',
      payload: {
        [modules]: {
          ...scope,
          data: success,
        },
      },
    })
  }
  yield loaded({
    modules,
    scope: {
      ...scope,
      data: success || [],
    },
  })
}

export function* CHANGE_TAB({ payload }) {
  const { tab } = payload
  yield put({
    type: 'cms/SET_STATE',
    payload: {
      tab,
    },
  })
  const suffix = tab
    .replace(/ /g, '-')
    .trim()
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .toLowerCase()
    .replace(/\s/g, '-')

  if (tab === 'faq' || tab === 'ebook') {
    yield put({
      type: 'cms/GET_PAGINATE',
      payload: {
        modules: tab,
        suffix,
        preload: {
          modules: `${tab}Category`,
          url: `${tab}-category`,
        },
      },
    })
  } else {
    yield put({
      type: 'cms/GET_PAGINATE',
      payload: {
        modules: tab,
        suffix,
      },
    })
  }
}

export function* loading(payload) {
  const { modules, scope } = payload
  yield put({
    type: 'cms/SET_STATE',
    payload: {
      [modules]: {
        ...scope,
        loading: true,
      },
    },
  })
}

export function* loaded(payload) {
  const { modules, scope } = payload
  yield put({
    type: 'cms/SET_STATE',
    payload: {
      [modules]: {
        ...scope,
        loading: false,
      },
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
    takeEvery(actions.GET_ALL, GET_ALL),
    takeEvery(actions.CHANGE_TAB, CHANGE_TAB),
  ])
}

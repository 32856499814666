import apiClient from 'services/axios'
import response from 'services/response'

export async function submaterialPaginate(payload) {
  const { perPage, currentPage, sort, search } = payload
  let res = null
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(`master/submaterial/${perPage}/${currentPage}/${sort}${params}`)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function submaterialListAll() {
  let res = null
  try {
    res = await apiClient.get(`master/submaterial/list-all`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function submaterialCreate(payload) {
  let res = null
  try {
    res = await apiClient.post(`master/submaterial`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function submaterialUpdate(id, data) {
  let res = null
  try {
    res = await apiClient.post(`master/submaterial/${id}`, data)
    return res.data
  } catch (error) {
    return false
  }
}

export async function submaterialFindById(id) {
  let res = null
  try {
    res = await apiClient.get(`master/submaterial/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function submaterialDelete(id) {
  let res = null
  try {
    res = await apiClient.delete(`master/submaterial/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_HOST}`,
  // timeout: 1000,
  headers: {
    accept: 'application/json',
    app_name: 'super-admin',
  },
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    // request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data) {
    notification.warning({
      message: data?.message || data?.data || 'Unknown error',
    })
  }
  return error
})

export default apiClient

const actions = {
  SET_STATE: 'users/SET_STATE',
  GET_PAGINATE: 'users/GET_PAGINATE',
  GET_SUMMARY: 'users/GET_SUMMARY',
  UPDATE_STATUS: 'users/UPDATE_STATUS',
  UPDATE: 'users/UPDATE',
  GET_USERS_ADMIN_PAGINATE: 'users/GET_USERS_ADMIN_PAGINATE',
}

export default actions

import apiClient from 'services/axios'

export async function fetchDistrict() {
  let res = false
  try {
    res = await apiClient.get(`master/district/list-all`)
    return res.data.data
  } catch (error) {
    return res
  }
}

export async function fetchProvince() {
  let res = false
  try {
    res = await apiClient.get(`master/province/list-all`)
    return res.data.data
  } catch (error) {
    return res
  }
}

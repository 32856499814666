import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  // morid routes
  {
    path: '/beranda',
    Component: lazy(() => import('pages/moorid/beranda')),
    exact: true,
  },
  {
    path: '/beranda/provinsi/:id',
    Component: lazy(() => import('pages/moorid/beranda/province')),
    exact: true,
  },
  {
    path: '/beranda/kota/:id',
    Component: lazy(() => import('pages/moorid/beranda/district')),
    exact: true,
  },
  {
    path: '/beranda/data-try-out/:id',
    Component: lazy(() => import('pages/moorid/beranda/tab/DataTryOutPane/DetailPage')),
    exact: true,
  },
  {
    path: '/master-try-out',
    Component: lazy(() => import('pages/moorid/master-try-out')),
    exact: true,
  },
  {
    path: '/paket',
    Component: lazy(() => import('pages/moorid/paket')),
    exact: true,
  },
  {
    path: '/bundle',
    Component: lazy(() => import('pages/moorid/bundle')),
    exact: true,
  },
  {
    path: '/bundle/detail/:bundleId',
    Component: lazy(() => import('pages/moorid/bundle/detail')),
    exact: true,
  },
  {
    path: '/kupon',
    Component: lazy(() => import('pages/moorid/kupon')),
    exact: true,
  },
  {
    path: '/kupon/:slug',
    Component: lazy(() => import('pages/moorid/kupon/detail')),
    exact: true,
  },
  {
    path: '/voucher',
    Component: lazy(() => import('pages/moorid/voucher')),
    exact: true,
  },
  {
    path: '/top-up',
    Component: lazy(() => import('pages/moorid/top-up')),
    exact: true,
  },
  {
    path: '/cms',
    Component: lazy(() => import('pages/moorid/cms')),
    exact: true,
  },
  {
    path: '/setting',
    Component: lazy(() => import('pages/moorid/setting')),
    exact: true,
  },
  {
    path: '/referral',
    Component: lazy(() => import('pages/moorid/referral')),
    exact: true,
  },
  {
    path: '/profile',
    Component: lazy(() => import('pages/profile')),
    exact: true,
  },
]

const mapStateToProps = ({ settings, user }) => ({
  routerAnimation: settings.routerAnimation,
  role: user.role,
})

const Router = ({ history, routerAnimation, role }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={() =>
                        ['super-akademik', 'akademik'].includes(role) ? (
                          <Redirect to="/master-try-out" />
                        ) : (
                          <Redirect to="/beranda" />
                        )
                      }
                    />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)

import React from 'react'
import {
  BerandaIcon,
  MasterTryOutIcon,
  PaketIcon,
  BundleIcon,
  KuponIcon,
  VoucherIcon,
  TopUpIcon,
  CmsIcon,
  ReferralIcon,
} from 'assets/icons'
import { SettingOutlined } from '@ant-design/icons'

export default async function getMenuData() {
  return [
    {
      title: 'Beranda',
      key: 'beranda',
      icon: <BerandaIcon className="align-middle text-center" />,
      url: '/beranda',
      roles: ['super-admin', 'admin', 'finance'],
    },
    {
      title: 'Master Try Out',
      key: 'master-try-out',
      icon: <MasterTryOutIcon className="align-middle text-center" />,
      url: '/master-try-out',
      roles: ['super-admin', 'admin', 'super-akademik', 'akademik'],
    },
    {
      title: 'Paket',
      key: 'paket',
      icon: <PaketIcon className="align-middle text-center" />,
      url: '/paket',
      roles: ['super-admin', 'admin', 'super-akademik', 'akademik'],
    },
    {
      title: 'Bundle',
      key: 'bundle',
      icon: <BundleIcon className="align-middle text-center" />,
      url: '/bundle',
      roles: ['super-admin', 'admin', 'super-akademik', 'akademik'],
    },
    {
      title: 'Kupon',
      key: 'kupon',
      icon: <KuponIcon className="align-middle text-center" />,
      url: '/kupon',
      roles: ['super-admin', 'admin', 'finance'],
    },
    {
      title: 'Voucher',
      key: 'voucher',
      icon: <VoucherIcon className="align-middle text-center" />,
      url: '/voucher',
      roles: ['super-admin', 'admin', 'finance'],
    },
    {
      title: 'Top Up',
      key: 'top-up',
      icon: <TopUpIcon className="align-middle text-center" />,
      url: '/top-up',
      roles: ['super-admin', 'admin', 'finance'],
    },
    {
      title: 'CMS',
      key: 'cms',
      icon: <CmsIcon className="align-middle text-center" />,
      url: '/cms',
      roles: ['super-admin', 'admin', 'super-akademik'],
    },
    {
      title: 'Referral',
      key: 'referral',
      icon: <ReferralIcon className="align-middle text-center" />,
      url: '/referral',
      roles: ['super-admin', 'admin', 'finance'],
    },
    {
      title: 'Setting',
      key: 'setting',
      icon: <SettingOutlined className="align-middle text-center" style={{ fontSize: 24 }} />,
      url: '/setting',
      roles: ['super-admin'],
    },
  ]
}

const actions = {
  SET_STATE: 'topup/SET_STATE',
  GET_PAGINATE: 'topup/GET_PAGINATE',
  GET_LIST_ALL: 'topup/GET_LIST_ALL',
  GET_SUMMARY: 'topup/GET_SUMMARY',
  GET_BY_ID: 'topup/GET_BY_ID',
  POST_CREATE: 'topup/POST_CREATE',
  POST_UPDATE: 'topup/POST_UPDATE',
  DELETE_DATA: 'topup/DELETE_DATA',
}

export default actions

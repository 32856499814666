const actions = {
  SET_STATE: 'voucher/SET_STATE',
  GET_PAGINATE: 'voucher/GET_PAGINATE',
  GET_LIST_ALL: 'voucher/GET_LIST_ALL',
  GET_BY_ID: 'voucher/GET_BY_ID',
  POST_CREATE: 'voucher/POST_CREATE',
  POST_UPDATE: 'voucher/POST_UPDATE',
  DELETE_DATA: 'voucher/DELETE_DATA',
  SUMMARY: 'voucher/SUMMARY',
}

export default actions

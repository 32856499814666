const actions = {
  SET_STATE: 'excercise/SET_STATE',
  GET_PAGINATE: 'excercise/GET_PAGINATE',
  GET_LIST_ALL: 'excercise/GET_LIST_ALL',
  GET_BY_ID: 'excercise/GET_BY_ID',
  POST_STORE: 'excercise/POST_STORE',
  POST_UPDATE: 'excercise/POST_UPDATE',
  DELETE_DATA: 'excercise/DELETE_DATA',
}

export default actions

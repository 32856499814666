import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: '-created_at',
      from: new Date(),
      to: new Date(),
    },
  },
  listTableUsersAdmin: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'username',
    },
  },
  summary: {},
  listAll: [],
  id: '',
  data: [],
  forms: {
    item: [
      {
        name: 'id',
        hidden: true,
        rules: [{ required: false }],
        children: {},
      },
      {
        name: 'user_type',
        hidden: true,
        rules: [{ required: false }],
        children: {},
        initialValue: process.env.REACT_APP_USER_TYPE,
      },
      {
        label: 'Email',
        name: 'email',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'input',
          type: 'email',
          placeholder: 'Ketik Email',
        },
      },
      {
        label: 'Username',
        name: 'username',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Ketik Username',
        },
      },
      {
        label: 'Nama Lengkap',
        name: 'nama_lengkap',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Ketik Nama Lengkap',
        },
      },
      {
        label: 'Password',
        name: 'password',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'password',
          type: 'password',
          placeholder: 'Ketik Password',
        },
      },
      {
        label: 'Role',
        name: ['role', 'role_display_name'],
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'selectCommon',
          placeholder: 'Pilih Hak Akses',
          className: 'form-control',
          style: {
            width: '100%',
          },
          data: 'role',
        },
      },
    ],
    fields: [
      {
        name: ['id'],
      },
      {
        name: ['username'],
      },
      {
        name: ['password'],
      },
      {
        name: ['nama_lengkap'],
      },
      {
        name: ['user_type'],
      },
      {
        name: ['email'],
      },
      {
        name: ['role', 'role_display_name'],
      },
    ],
  },
  loading: false,
}

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

import apiClient from 'services/axios'
import response from 'services/response'

export async function settingPaginate(payload) {
  const { perPage, currentPage, sort, search, type } = payload
  let res = null
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(`master/setting/${type}/${perPage}/${currentPage}/${sort}${params}`)
    return response({ ...res.data, search, sort, type })
  } catch (error) {
    return false
  }
}

export async function settingType() {
  let res = null
  try {
    res = await apiClient.get(`master/setting/list-all-type`)
    return res.data.data
  } catch (error) {
    return false
  }
}

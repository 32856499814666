import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 1000,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'created_at',
    },
  },
  listAll: [],
  id: '',
  data: '',
  forms: {
    item: [
      {
        name: 'id',
        hidden: true,
        rules: [{ required: false }],
        children: {},
      },
      {
        name: 'answer_name',
        rules: [{ required: true }],
        className: 'col-9',
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Ketik Sub Materi',
          className: 'form-control',
        },
      },
    ],
    fields: [
      {
        name: ['id'],
      },
      {
        name: ['answer_name'],
      },
    ],
  },
  loading: false,
}

export default function answerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

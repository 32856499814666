import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import { topupPaginate, topupListAll } from 'services/topup'
import actions from './actions'

export function* GET_PAGINATE() {
  const { listTable } = yield select(state => state.topup)
  yield loading()
  const success = yield call(topupPaginate, listTable.paginate)
  if (success) {
    yield put({
      type: 'topup/SET_STATE',
      payload: {
        listTable: {
          data: success.data,
          paginate: {
            ...listTable.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_SUMMARY() {
  const success = yield call(topupListAll)

  if (success) {
    yield put({
      type: 'topup/SET_STATE',
      payload: {
        summary: success,
      },
    })
  }
}

export function* loading() {
  yield put({
    type: 'topup/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'topup/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
    takeEvery(actions.GET_SUMMARY, GET_SUMMARY),
  ])
}

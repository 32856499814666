import actions from './actions'

const model = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'packet_name',
    },
  },
  listAll: [],
  id: '',
  data: '',
  forms: {
    item: [
      {
        name: 'id',
        hidden: true,
        rules: [{ required: false }],
        children: {
          component: '',
        },
      },
      {
        // name: 'id',
        hidden: true,
        // className: 'col-6 mt-auto',
        name: 'packet_may_repeat_task_when_true_how_much',
        initialValue: 0,
        children: {},
        // group: 1,
        // rules: [{ required: false }],
        // children: {
        //   component: 'input',
        //   placeholder: 'Berapa?',
        //   addonAfter: 'Kali',
        // },
      },
      {
        //   label: 'Boleh Ulang ?',
        //   className: 'col',
        hidden: true,
        name: 'packet_may_repeat_task',
        //   rules: [{ required: true }],
        initialValue: 0,
        children: {
          //     component: 'radio',
          //     options: [
          //       { label: 'Boleh', value: 1 },
          //       { label: 'Tidak', value: 0 },
          //     ],
          //     optionType: 'button',
          //     buttonStyle: 'solid',
          //     className: 'd-flex',
        },
      },

      {
        label: 'Nama Paket',
        name: 'packet_name',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Ketik Nama Paket',
          className: 'form-control',
        },
      },
      {
        label: 'Deskripsi Paket',
        name: 'packet_description',
        className: 'col-12 border-primary',
        rules: [{ required: false }],
        children: {
          component: 'editor',
        },
      },
      {
        label: 'Kategori Try Out',
        className: 'col-12',
        children: {
          component: 'select',
          placeholder: 'Pilih Kategori',
          className: 'form-control',
          style: {
            width: '100%',
          },
          data: 'category',
        },
      },
      {
        label: 'Penggabungan Timer',
        className: 'col-6',
        name: 'packet_is_time_merger',
        rules: [{ required: true }],
        initialValue: false,
        children: {
          component: 'radio',
          options: [
            { label: 'Dipisah', value: false },
            { label: 'Digabung', value: true },
          ],
          optionType: 'button',
          buttonStyle: 'solid',
          className: 'd-flex',
        },
      },
      {
        noStyle: true,
        shouldUpdate: 'packet_is_time_merger',
        getFieldValue: ['packet_is_time_merger', true],
        className: 'col-6 mt-auto',
        name: 'packet_is_time_merger_when_true_duration',
        rules: [{ required: false }],
        children: {
          component: 'input',
          placeholder: 'Durasi Pengerjaan Seluruh Soal',
          addonAfter: 'Menit',
          addonBefore: 'Durasi',
        },
      },
    ],
    fields: [
      {
        name: ['id'],
      },
      {
        name: ['packet_name'],
      },
      {
        name: ['packet_description'],
      },
      {
        name: ['category'],
      },
      {
        name: ['packet_simulation_type'],
      },
      {
        name: ['packet_may_repeat_task_when_true_how_much'],
      },
      {
        name: ['packet_is_time_merger'],
      },
      {
        name: ['packet_is_time_merger_when_true_duration'],
      },
    ],
  },
  loading: false,
}
const initialState = {
  konsep: { ...model },
  terbit: { ...model },
  detail: { category: { materials: [] } },
  id: '',
  loading: false,
  visible: false,
}

export default function packetReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

import actions from './actions'
import { banner, video, faqCategory, faq, ebookCategory, ebook } from './forms'

function model(p, q) {
  return {
    listTable: {
      data: [],
      paginate: {
        currentPage: 1,
        perPage: 10,
        pageCount: 0,
        totalCount: 0,
        search: '',
        sort: p,
        ...(q || {}),
      },
    },
    listAll: [],
    id: '',
    data: [],
    loading: false,
  }
}

const initialState = {
  banner: {
    ...model(banner().forms.item[1].name),
    ...banner(),
  },
  video: {
    ...model(video().forms.item[1].name),
    ...video(),
  },
  faqCategory: {
    ...model(faqCategory().forms.item[2].name),
    ...faqCategory(),
  },
  faq: {
    ...model(faq().forms.item[1].name, { categoryId: 'all' }),
    ...faq(),
  },
  ebookCategory: {
    ...model(ebookCategory().forms.item[1].name),
    ...ebookCategory(),
  },
  ebook: {
    ...model(ebook().forms.item[1].name),
    ...ebook(),
  },
  loading: false,
  tab: 'banner',
}

export default function cmsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

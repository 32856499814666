import apiClient from 'services/axios'
import response from 'services/response'

export async function activityUserPaginate(payload) {
  const { from, perPage, currentPage, sort, search } = payload
  const params = search ? `/${search}` : ''
  try {
    const { data } = await apiClient(
      `tryout/dashboard-activity-user-admin/list/${from}/${perPage}/${currentPage}/${sort}${params}`,
    )
    return response({ ...data, search, sort })
  } catch (error) {
    return error
  }
}

export async function userMooridPaginate(payload) {
  let res = false
  const { from, to, perPage, currentPage, sort, search } = payload
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient(
      `tryout/dashboard-user-admin/list/${from}/${to}/all/all/${perPage}/${currentPage}/${sort}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return res
  }
}

export async function activityUserSummary(payload) {
  let res = false
  const { fromTo } = payload
  try {
    res = await apiClient(`tryout/dashboard-activity-user-admin/summary/${fromTo[0]}/${fromTo[1]}`)
    return res.data
  } catch (error) {
    return res
  }
}

export async function dataTryOutSummary() {
  let res = false
  try {
    res = await apiClient(`tryout/dashboard-data-tryout-admin/summary`)
    return res.data
  } catch (error) {
    return res
  }
}

export async function dataTryOutPaginate(payload) {
  let res = false
  const { perPage, currentPage, sort, search } = payload
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient(
      `/tryout/dashboard-data-tryout-admin/list/${perPage}/${currentPage}/${sort}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return res
  }
}

export async function dataTryOutIdPaginate(payload) {
  let res = false
  const { currentPage, sort, search, id } = payload

  const params = search ? `/${search}` : ''
  try {
    res = await apiClient(
      `/tryout/dashboard-data-tryout-admin/list-by-category/${id}/1000/${currentPage}/${sort}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return res
  }
}

export async function userAdminProvincePaginate(payload) {
  let res = false
  const { perPage, currentPage, sort, search, from, to } = payload
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient(
      `tryout/dashboard-user-admin/list-by-province/${from}/${to}/${perPage}/${currentPage}/${sort}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return res
  }
}

export async function userAdminSummary(payload) {
  const { from, to } = payload
  try {
    const res = await apiClient(`tryout/dashboard-user-admin/summary/${from}/${to}`)
    return res.data
  } catch (error) {
    return error
  }
}

export async function userAdminDistrictPaginate(payload) {
  let res = false
  const { perPage, currentPage, sort, search, from, to } = payload
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient(
      `tryout/dashboard-user-admin/list-by-district/${from}/${to}/${perPage}/${currentPage}/${sort}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return res
  }
}

export async function financePaginate(payload) {
  let res = false
  const { perPage, currentPage, sort, search, from, to } = payload
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient(
      `finance/dashboard-finance/list/${from}/${to}/${perPage}/${currentPage}/${sort}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return res
  }
}

export async function financeSummary(payload) {
  const { from, to } = payload
  let res = false
  try {
    res = await apiClient(`finance/dashboard-finance/summary/${from}/${to}`)
    return res.data
  } catch (error) {
    return res
  }
}

export async function financeGraph(payload) {
  const { from, to } = payload
  try {
    const { data } = await apiClient(`finance/dashboard-finance/graph/${from}/${to}`)
    return data
  } catch (error) {
    return error
  }
}

export async function userAdminDistrictIdPaginate(payload) {
  let res = false
  const { from, to, perPage, currentPage, sort, search, id } = payload
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient(
      `tryout/dashboard-user-admin/list/${from}/${to}/all/${id}/${perPage}/${currentPage}/${sort}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return res
  }
}

export async function userAdminProvinceIdPaginate(payload) {
  let res = false
  const { from, to, perPage, currentPage, sort, search, id } = payload
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient(
      `tryout/dashboard-user-admin/list/${from}/${to}/${id}/all/${perPage}/${currentPage}/${sort}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return res
  }
}

import moment from 'moment'
import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 100,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'bundle_name',
      bundleSold: 'konsep',
    },
  },
  listAll: [],
  tabs: 'konsep',
  id: '',
  data: '',
  forms: {
    item: [
      {
        name: 'id',
        hidden: true,
        rules: [{ required: false }],
        children: {},
      },
      {
        label: 'Nama Bundle',
        name: 'bundle_name',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Ketik Nama Bundle',
          className: 'form-control',
        },
      },
      {
        label: 'Pilih & Upload file',
        name: 'bundle_image_url',
        rules: [{ required: false }],
        className: 'col',
        help: 'Maks 1MB JPG, JPEG, PNG rasio 1:1',
        children: {
          component: 'uploadCustom',
          type: 'text',
          placeholder: 'Upload Gambar',
          url: 'bundle',
        },
      },

      {
        label: 'Bundle Selalu Tersedia',
        className: 'col-4',
        name: 'bundle_is_always_avaiable',
        rules: [{ required: true }],
        children: {
          component: 'radioCommonSetOnChange',
          options: [
            { label: 'Ya', value: 'true' },
            { label: 'Tidak', value: 'false' },
          ],
          optionType: 'button',
          buttonStyle: 'solid',
          onChange: {
            value: [moment(), moment().add(50, 'years')],
            target: 'bundle_available_date_range',
            default: [moment(), moment()],
          },
        },
      },
      {
        label: 'Pilih Paket (Packet yang sudah ada di bundle lain tidak boleh dipilih kembali !)',
        className: 'col-12',
        rules: [{ required: true }],
        children: {
          component: 'select',
          placeholder: 'Pilih Paket',
          mode: 'multiple',
          style: {
            width: '100%',
          },
          data: 'packet',
        },
      },
      {
        label: 'Keterangan',
        name: 'bundle_description',
        rules: [{ required: false }],
        className: 'col-12',
        children: {
          component: 'textarea',
          type: 'text',
          placeholder: 'Ketik Nama Bundle',
          className: 'form-control',
        },
      },
      {
        label: 'Tanggal Mulai Selesai',
        name: 'bundle_available_date_range',
        group: 'false',
        rules: [{ required: false }],
        className: 'col-12',
        children: {
          component: 'datetimerange',
          className: 'w-100',
        },
      },
      {
        label: 'Harga',
        className: 'col-4',
        name: 'bundle_price_type',
        rules: [{ required: true }],
        children: {
          component: 'radio',
          options: [
            { label: 'Gratis', value: 'gratis' },
            { label: 'Bayar', value: 'bayar' },
          ],
          optionType: 'button',
          buttonStyle: 'solid',
        },
      },
      {
        className: 'col-4 mt-auto pr-3',
        name: 'bundle_price_coin_when_price_type_bayar',
        rules: [{ required: false }],
        children: {
          component: 'input',
          placeholder: 'Berapa Koin ?',
          addonAfter: 'Koin',
        },
      },
      {
        label: 'Pembelian Custom',
        className: 'col-12',
        group: 'bayar',
        rules: [{ required: false }],
        children: {
          component: 'formList',
          name: 'bundleCustomSold',
          childComponent: [
            {
              className: 'col-2',
              name: 'bundle_custom_sold_from',
              rules: [{ required: false }],
              children: {
                component: 'input',
                className: 'mb',
                addonAfter: ' - ',
              },
            },
            {
              className: 'col-3 mt-auto',
              name: 'bundle_custom_sold_to',
              rules: [{ required: false }],
              children: {
                component: 'input',
                addonAfter: 'orang',
              },
            },
            {
              className: 'col-5 mt-auto',
              name: 'bundle_custom_sold_coin_for_one_person',
              rules: [{ required: false }],
              children: {
                component: 'input',
                addonAfter: 'Koin per orang',
              },
            },
          ],
        },
      },
      {
        label: 'Persyaratan',
        className: 'row col-12',
        group: 'gratis',
        rules: [{ required: false }],
        children: {
          component: 'formList',
          name: 'bundleCustomTerm',
          className: 'row',
          childComponent: [
            {
              component: 'input',
              name: 'bundle_custom_term_name',
              className: 'col-10',
              children: {
                placeholder: `Ketik syarat, cth : Upload screenshoot follow ig ${process.env.REACT_APP_NAME} `,
              },
            },
          ],
        },
      },
    ],
    fields: [
      { name: ['bundle_name'] },
      { name: ['bundle_image_url'] },
      { name: ['bundle_description'] },
      { name: ['bundle_price_type'] },
      { name: ['bundle_price_coin_when_price_type_bayar'] },
      { name: ['bundle_price_coin_when_price_type_promo'] },
      { name: ['bundle_is_always_avaiable'] },
      { name: ['bundle_available_date_range'] },
      { name: ['bundleCustomSold'] },
      { name: ['bundleCustomTerm'] },
      { name: ['packet'] },
    ],
  },
  loading: false,
  bundleSoldPaginate: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 500,
      pageCount: 0,
      totalCount: 0,
      search: '',
      status: 'all',
      bundleId: null,
      date: 'all',
      sort: 'created_at',
    },
  },
}

export default function bundleReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const actions = {
  SET_STATE: 'packet/SET_STATE',
  GET_PAGINATE: 'packet/GET_PAGINATE',
  GET_LIST_ALL: 'packet/GET_LIST_ALL',
  GET_LIST_ALL_PUBLISH: 'packet/GET_LIST_ALL_PUBLISH',
  GET_BY_ID: 'packet/GET_BY_ID',
  POST_CREATE: 'packet/POST_CREATE',
  POST_UPDATE: 'packet/POST_UPDATE',
  DELETE_DATA: 'packet/DELETE_DATA',
}

export default actions

export default function banner() {
  return {
    forms: {
      item: [
        {
          name: 'id',
          hidden: true,
          rules: [{ required: false }],
          children: {},
        },
        {
          label: 'Judul Banner',
          name: 'cms_banner_name',
          rules: [{ required: true }],
          className: 'col-6',
          children: {
            component: 'input',
            type: 'text',
            placeholder: 'Ketik Judul Banner',
          },
        },
        {
          label: 'Status Aktif',
          name: 'cms_banner_is_active',
          rules: [{ required: true }],
          className: 'col-6',
          initialValue: true,
          children: {
            component: 'radio',
            options: [
              { label: 'Ya', value: true },
              { label: 'Tidak', value: false },
            ],
            optionType: 'button',
            buttonStyle: 'solid',
          },
        },
        {
          label: 'Urutan Banner',
          name: 'cms_banner_order',
          rules: [{ required: true }],
          className: 'col-6',
          children: {
            component: 'input',
            type: 'text',
            placeholder: 'Ketik Urutan Banner',
          },
        },
        {
          label: 'Tipe Banner',
          name: 'cms_banner_tipe',
          rules: [{ required: true }],
          className: 'col-6',
          children: {
            component: 'radio',
            options: [
              { label: 'Register', value: 'register' },
              { label: 'Dashboard', value: 'dashboard' },
            ],
            optionType: 'button',
            buttonStyle: 'solid',
          },
        },
        {
          label: 'Link Tujuan',
          name: 'cms_banner_url_destination',
          group: 'dashboard',
          rules: [{ required: false }],
          className: 'col-12',
          children: {
            component: 'input',
            type: 'text',
            placeholder: 'Ketik Link Tujuan',
          },
        },
        {
          label: 'Deskripsi',
          name: 'cms_banner_description',
          rules: [{ required: true }],
          className: 'col-12',
          children: {
            component: 'textarea',
            type: 'text',
            placeholder: 'Ketik Deskripsi',
          },
        },
        {
          label: 'Header',
          name: 'cms_banner_header',
          rules: [{ required: true }],
          className: 'col-12',
          children: {
            component: 'input',
            type: 'text',
            placeholder: 'Ketik header',
          },
        },
        {
          label: 'Gambar',
          name: 'cms_banner_image_url',
          rules: [{ required: true }],
          className: 'col-12',
          help: 'Banner harus berukuran 1920 x 1080',
          children: {
            component: 'uploadCustom',
            url: 'cms',
          },
        },
        {
          label: 'Footer',
          name: 'cms_banner_footer',
          rules: [{ required: true }],
          className: 'col-12',
          children: {
            component: 'textarea',
            type: 'text',
            placeholder: 'Ketik Footer',
          },
        },
      ],
      fields: [
        { name: ['id'] },
        { name: ['cms_banner_order'] },
        { name: ['cms_banner_name'] },
        { name: ['cms_banner_description'] },
        { name: ['cms_banner_header'] },
        { name: ['cms_banner_footer'] },
        { name: ['cms_banner_url_destination'] },
        { name: ['cms_banner_image_url'] },
        { name: ['cms_banner_is_active'] },
        { name: ['cms_banner_tipe'] },
      ],
    },
  }
}

import apiClient from 'services/axios'
import response from 'services/response'

export async function usersAdminPaginate(payload) {
  const { perPage, currentPage, sort, search } = payload
  const params = search ? `/${search}` : ''
  try {
    const { data } = await apiClient.get(`master/user/${perPage}/${currentPage}/${sort}${params}`)
    return response({ ...data, search, sort })
  } catch (error) {
    return false
  }
}

export async function usersAdminFindById(id) {
  let res = null
  try {
    res = await apiClient.get(`master/users/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function usersAdminDelete(id) {
  let res = null
  try {
    res = await apiClient.delete(`master/users/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function usersPaginate(payload) {
  const { perPage, currentPage, sort, search, from } = payload
  const params = search ? `/${search}` : ''
  try {
    const { data } = await apiClient.get(
      `tryout/dashboard-activity-user-admin/list/${from}/${perPage}/${currentPage}/${sort}${params}`,
    )
    return response({ ...data, search, sort })
  } catch (error) {
    return false
  }
}

export async function usersSummary(payload) {
  const { from, to } = payload
  let res = false
  try {
    res = await apiClient.get(`tryout/dashboard-activity-user-admin/summary/${from}/${to}`)
    return res.data.data
  } catch (error) {
    return error
  }
}

export async function usersCreate(payload) {
  let res = null
  try {
    res = await apiClient.post(`master/users`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function usersUpdate({ id }) {
  let res = null
  try {
    res = await apiClient.get(`master/user/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function usersUpdateStatus({ id }) {
  let res = null
  try {
    res = await apiClient.get(`master/user/change-active-status/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

import actions from './actions'

const paginateModel = sort => {
  return {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 100,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort,
    },
  }
}

const initialState = {
  tab: 'DataUserPane',
  activityUserSummary: {
    fromTo: [new Date(new Date().getFullYear(), 0), new Date()],
    data: {
      visited_tryout_today: 0,
      visited_tryout_this_period: 0,
      worked_tryout_this_period: 0,
    },
  },
  activityUserPaginate: paginateModel('created_at'),
  userMooridPaginate: paginateModel('username'),
  userAdminTab: 'UserMooridComponent',
  userAdminSummary: {
    user_already_topup: 0,
    user_not_topup: 0,
    user_total: 0,
  },
  userAdminProvincePaginate: paginateModel('province_name'),
  userAdminProvinceIdPaginate: paginateModel('username'),
  userAdminDistrictPaginate: paginateModel('district_name'),
  userAdminDistrictIdPaginate: paginateModel('username'),
  dataTryOutSummary: {
    packet_available: 0,
    packet_sold: 0,
  },
  dataTryOutPaginate: paginateModel('category_name'),
  dataTryOutIdPaginate: paginateModel('packet_name'),
  financeSummary: {
    total_balance: 0,
    total_used_balance: 0,
    outstanding_balance: 0,
    student_topup_amount: 0,
    total_invoice_amount: 0,
    payment_percentage: 0,
  },
  financePaginate: paginateModel('bundle_name'),
  financeGraph: [],
  loading: false,
}

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

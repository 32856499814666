import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'coupon_name',
    },
  },
  listAll: [],
  id: '',
  data: '',
  forms: {
    item: [
      {
        name: 'id',
        hidden: true,
        rules: [{ required: false }],
        children: {},
      },
      {
        label: 'Nama Kupon',
        name: 'coupon_name',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'input',
          placeholder: 'Ketik Kupon',
          className: 'form-control',
        },
      },
      {
        label: 'Gambar',
        name: 'coupon_image_url',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'uploadCustom',
          placeholder: 'Pilih Gambar',
          className: 'form-control',
          url: 'coupon',
        },
      },
      {
        label: 'Kode Kupon',
        name: 'coupon_code',
        rules: [{ required: true }],
        className: 'col-6',
        children: {
          component: 'input',
          placeholder: 'Ketik Kupon',
          className: 'form-control',
          help: 'Maksimal 5 Mb',
        },
      },
      {
        label: 'Nominal Diskon',
        name: 'coupon_amount',
        rules: [{ required: true }],
        className: 'col-6',
        children: {
          component: 'input',
          placeholder: 'Ketik Nominal',
          className: 'form-control',
        },
      },
      {
        label: 'Maksimal Penggunaan Kupon',
        name: 'coupon_used_when_is_limited',
        rules: [{ required: false }],
        className: 'col-12 mt-auto',
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Berapa?',
          addonAfter: 'Kali',
        },
      },
      {
        label: 'Masa Aktif',
        name: 'coupon_valid_date_range',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'daterange',
          className: 'w-100',
        },
      },
      {
        label: 'Kupon Deskripsi',
        name: 'coupon_description',
        rules: [{ required: false }],
        className: 'col-12',
        children: {
          component: 'textarea',
        },
      },
    ],
    fields: [
      { name: ['id'] },
      { name: ['coupon_code'] },
      { name: ['coupon_name'] },
      { name: ['coupon_image_url'] },
      { name: ['coupon_used_when_is_limited'] },
      { name: ['coupon_amount'] },
      { name: ['coupon_valid_date_range'] },
      { name: ['coupon_description'] },
    ],
  },
  loading: false,
}

export default function couponReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

/* eslint-disable no-plusplus */
import apiClient from 'services/axios'
import response from 'services/response'

export async function answerPaginate(payload) {
  const { perPage, currentPage, sort, search, excerciseId } = payload
  let res = null
  try {
    res = await apiClient.get(`master/answer/${excerciseId}/${perPage}/${currentPage}/${sort}`)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function answerStore(payload) {
  let res = null
  const suffix = payload.id ? `/${payload.id}` : ``
  try {
    res = await apiClient.post(`master/answer${suffix}`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

async function fler({ e, data, id }) {
  return apiClient.post(`master/answer${data.id ? `/${data.id}` : ``}`, {
    answer_is_true: false,
    answer_is_true_score: 0,
    ...e,
    excercise: {
      id,
    },
  })
}

export async function answerStoreBulk(payload) {
  const { id, data } = payload
  const resp = []
  try {
    // eslint-disable-next-line no-restricted-syntax
    for await (const e of data) {
      const res = await fler({ e, id, data })
      resp.push(res.data)
    }
    return true
  } catch (error) {
    return false
  }
}

export async function answerDelete(id) {
  let res = null
  try {
    res = await apiClient.delete(`master/answer/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

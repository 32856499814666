import apiClient from 'services/axios'
import response from 'services/response'

export async function excercisePaginate(payload) {
  const { perPage, currentPage, sort, search, packetId } = payload
  let res = null
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(
      `master/excercise/${packetId}/${perPage}/${currentPage}/${sort}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function excerciseListAll() {
  let res = null
  try {
    res = await apiClient.get(`master/excercise/list-all`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function excerciseStore(payload) {
  let res = null
  const suffix = payload.id ? `/${payload.id}` : ``
  try {
    res = await apiClient.post(`master/excercise${suffix}`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function excerciseUpdate(id, data) {
  let res = null
  try {
    res = apiClient.post(`master/excercise/${id}`, data)
    return res.data
  } catch (error) {
    return false
  }
}

export async function excerciseFindById(id) {
  let res = null
  try {
    res = apiClient.get(`master/excercise/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function excerciseDelete(id) {
  let res = null
  try {
    res = apiClient.delete(`master/excercise/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

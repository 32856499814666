export default function faq() {
  return {
    forms: {
      item: [
        {
          name: 'id',
          hidden: true,
          rules: [{ required: false }],
          children: {},
        },
        {
          label: 'Pertanyaan',
          name: 'cms_faq_question',
          rules: [{ required: true }],
          className: 'col-12',
          children: {
            component: 'input',
            type: 'text',
            placeholder: 'Ketik pertanyaan yang sering ditanyakan user',
          },
        },
        {
          label: 'Kategori',
          name: ['cmsFaqCategory', 'id'],
          rules: [{ required: true }],
          className: 'col-6',
          children: {
            component: 'selectCommon',
            placeholder: 'Pilih Kategori',
            data: 'cms_faq_category',
          },
        },
        {
          label: 'Urutan FAQ',
          name: 'cms_faq_order',
          rules: [{ required: true }],
          className: 'col-6',
          children: {
            component: 'input',
            placeholder: 'Ketik Urutan FAQ',
          },
        },
        {
          label: 'Deskripsi',
          name: 'cms_faq_answer',
          rules: [{ required: true }],
          className: 'col-12',
          children: {
            component: 'editor',
            type: 'text',
            placeholder: 'Ketik Deskripsi',
          },
        },
      ],
      fields: [
        { name: ['id'] },
        { name: ['cms_faq_order'] },
        { name: ['cms_faq_question'] },
        { name: ['cms_faq_answer'] },
        { name: ['cmsFaqCategory', 'id'] },
      ],
    },
  }
}

import apiClient from 'services/axios'
import response from 'services/response'

export async function materialPaginate(payload) {
  const { perPage, currentPage, sort, search } = payload
  let res = null
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(`master/material/${perPage}/${currentPage}/${sort}${params}`)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function materialListAll() {
  let res = null
  try {
    res = await apiClient.get(`master/material/list-all`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function materialCreate(payload) {
  let res = null
  try {
    res = apiClient.post(`master/material`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function materialUpdate(id, data) {
  let res = null
  try {
    res = apiClient.post(`master/material/${id}`, data)
    return res.data
  } catch (error) {
    return false
  }
}

export async function materialFindById(id) {
  let res = null
  try {
    res = await apiClient.get(`master/material/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function materialDelete(id) {
  let res = null
  try {
    res = apiClient.delete(`master/material/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

const actions = {
  SET_STATE: 'bundle/SET_STATE',
  GET_PAGINATE: 'bundle/GET_PAGINATE',
  GET_LIST_ALL: 'bundle/GET_LIST_ALL',
  GET_BY_ID: 'bundle/GET_BY_ID',
  POST_CREATE: 'bundle/POST_CREATE',
  POST_UPDATE: 'bundle/POST_UPDATE',
  DELETE_DATA: 'bundle/DELETE_DATA',
  GET_BUNDLE_SOLD: 'bundle/GET_BUNDLE_SOLD',
}

export default actions

import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'material_name',
    },
  },
  listAll: [],
  id: '',
  data: { submaterials: [] },
  forms: {
    item: [
      {
        name: 'id',
        hidden: true,
        rules: [{ required: false }],
        children: {
          component: '',
        },
      },
      {
        label: 'Materi',
        name: 'material_name',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Ketik Materi',
          className: 'form-control',
        },
      },
      {
        label: 'Urutan Materi',
        name: 'material_order',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Ketik Urutan Materi',
          className: 'form-control',
        },
      },
      {
        label: 'Sub Materi',
        name: 'submaterials',
        className: 'col-12',
        rules: [{ required: true }],
        help: 'Bisa lebih dari 1 sub materi',
        children: {
          component: 'select',
          placeholder: 'Pilih Sub Materi',
          mode: 'multiple',
          style: {
            width: '100%',
          },
          data: 'submaterial',
        },
      },
      {
        label: 'Tipe Scoring',
        className: 'col-12',
        name: 'material_scoring_type',
        rules: [{ required: true }],
        children: {
          component: 'radio',
          options: [
            { label: 'Benar, Salah, Kosong', value: 'benar,salah,kosong' },
            { label: 'Menurut Pilihan', value: 'menurut_pilihan' },
            { label: 'IRT', value: 'irt' },
          ],
          optionType: 'button',
          buttonStyle: 'solid',
        },
      },
      {
        label: 'Skor Benar',
        name: 'material_true_score',
        group: 'benar,salah,kosong',
        className: 'col-4 pr-2',
        children: {
          component: 'input',
          type: 'text',
          className: 'form-control',
        },
      },
      {
        label: 'Skor Salah',
        name: 'material_false_score',
        group: 'benar,salah,kosong',
        className: 'col-4 pr-2',
        children: {
          component: 'input',
          type: 'text',
          className: 'form-control',
        },
      },
      {
        label: 'Skor Kosong',
        name: 'material_empty_score',
        group: 'benar,salah,kosong',
        className: 'col-4 pr-2',
        children: {
          component: 'input',
          type: 'text',
          className: 'form-control',
        },
      },
      {
        label: 'Mudah',
        name: 'material_easy_percentage',
        group: 'irt',
        className: 'col-6',
        rules: [{ message: 'Persentase Mudah Tidak Boleh Kosong' }],
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Berapa persen ?',
          addonBefore: 'Lebih dari',
        },
      },
      {
        name: 'material_easy_score',
        group: 'irt',
        className: 'col-6',
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Berapa skornya ?',
        },
      },
      {
        label: 'Sedang',
        name: 'material_medium_percentage_from',
        group: 'irt',
        dependencies: ['material_easy_percentage'],
        rules: [
          { message: 'Persentase Sedang Tidak Boleh Kosong' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('material_easy_percentage') < value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error('Persentase Sedang Harus Lebih Besar Dari Persentase Mudah'),
              )
            },
          }),
        ],
        className: 'col-4',
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Berapa persen ?',
          addonAfter: 'Sampai',
        },
      },
      {
        name: 'material_medium_percentage_to',
        group: 'irt',
        className: 'col-4',
        dependencies: ['material_medium_percentage_from'],
        rules: [
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('material_medium_percentage_from') < value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error('Persentase Sedang Dari Harus Lebih Besar Dari Persentase Sampai'),
              )
            },
          }),
        ],
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Berapa persen ?',
        },
      },
      {
        name: 'material_medium_score',
        group: 'irt',
        className: 'col-4',
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Berapa skornya ?',
        },
      },
      {
        label: 'Sulit',
        name: 'material_hard_percentage',
        group: 'irt',
        className: 'col-6',
        dependencies: ['material_medium_percentage_to'],
        rules: [
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('material_medium_percentage_to') < value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error('Persentase Sulit Harus Lebih Besar Dari Persentase Sedang Sampai'),
              )
            },
          }),
        ],
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Berapa persen ?',
          addonBefore: 'Lebih dari',
        },
      },
      {
        name: 'material_hard_score',
        group: 'irt',
        className: 'col-6',
        children: {
          component: 'input',
          type: 'text',
          placeholder: 'Berapa skornya ?',
        },
      },
      {
        label: 'Minimal Skor',
        name: 'material_minimum_score',
        className: 'col-6 pr-2',
        children: {
          component: 'input',
          type: 'text',
        },
      },
      {
        label: 'Durasi Pengerjaan Soal',
        name: 'material_task_duration',
        className: 'col-6',
        rules: [{ required: true }],
        children: {
          component: 'input',
          type: 'text',
          addonAfter: 'Menit',
        },
      },
    ],
    fields: [
      {
        name: ['id'],
      },
      {
        name: ['material_name'],
      },
      {
        name: ['submaterials'],
      },
      {
        name: ['material_scoring_type'],
      },
      {
        name: ['material_minimum_score'],
      },
      {
        name: ['material_task_duration'],
      },
      {
        name: ['material_true_score'],
      },
      {
        name: ['material_false_score'],
      },
      {
        name: ['material_empty_score'],
      },
      {
        name: ['material_easy_percentage'],
      },
      {
        name: ['material_medium_percentage_from'],
      },
      {
        name: ['material_medium_percentage_to'],
      },
      {
        name: ['material_hard_percentage'],
      },
      {
        name: ['material_easy_score'],
      },
      {
        name: ['material_medium_score'],
      },
      {
        name: ['material_hard_score'],
      },
      {
        name: ['material_order'],
      },
    ],
  },
  loading: false,
}

export default function materialReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

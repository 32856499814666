import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      status: 'semua',
      sort: 'created_at',
      date: 'all',
    },
  },
  listAll: [],
  id: '',
  data: '',
  summary: {
    menunggu: 0,
    berhasil: 0,
    dibatalkan: 0,
    kadaluarsa: 0,
  },
  forms: {
    item: [
      {
        name: 'id',
        hidden: true,
        rules: [{ required: false }],
        children: {},
      },
      {
        label: 'Nama Voucher',
        name: 'topup_name',
        rules: [{ required: true }],
        className: 'col-12',
        children: {
          component: 'input',
          placeholder: 'Ketik Nama Top Up',
          className: 'form-control',
        },
      },
      {
        label: 'Jumlah Voucher',
        name: 'topup_qty',
        rules: [{ required: true }],
        className: 'col-6',
        children: {
          component: 'input',
          placeholder: 'Ketik Jumlah Voucher',
          className: 'form-control',
        },
      },
      {
        label: 'Nominal Voucher',
        name: 'topup_amount',
        rules: [{ required: true }],
        className: 'col-6',
        children: {
          component: 'input',
          placeholder: 'Ketik Top Up',
          addonBefore: 'Rp',
        },
      },
      {
        label: 'Perkiraan Budget Voucher',
        name: 'topup_amount',
        rules: [{ required: true }],
        className: 'col-12',
        help: 'Hasil dari perkalian jumlah dan nominal topup',
        children: {
          component: 'input',
          placeholder: 'Rp. ',
          className: 'form-control',
          disabled: 1,
        },
      },
    ],
    fields: [
      { name: ['id'] },
      { name: ['topup_name'] },
      { name: ['topup_qty'] },
      { name: ['topup_amount'] },
    ],
  },
  loading: false,
}

export default function topupReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

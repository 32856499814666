import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  materialPaginate,
  materialListAll,
  // materialCreate,
  // materialUpdate,
  materialFindById,
  // materialDelete,
} from 'services/material'
import actions from './actions'

export function* GET_PAGINATE() {
  const { listTable } = yield select(state => state.material)
  yield loading()
  const success = yield call(materialPaginate, listTable.paginate)
  if (success) {
    yield put({
      type: 'material/SET_STATE',
      payload: {
        listTable: success,
      },
    })
  }
  yield loaded()
}

export function* GET_LIST_ALL() {
  const success = yield call(materialListAll)
  if (success) {
    yield put({
      type: 'material/SET_STATE',
      payload: {
        listAll: success,
      },
    })
  }
}

export function* GET_BY_ID() {
  yield loading()
  const { materialId } = yield select(state => state.excercise)
  const success = yield call(materialFindById, materialId)
  if (success) {
    yield put({
      type: 'material/SET_STATE',
      payload: {
        data: success,
      },
    })
  }
  yield loaded()
}

export function* loading() {
  yield put({
    type: 'material/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'material/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.SET_STATE,SET_STATE),
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
    takeEvery(actions.GET_LIST_ALL, GET_LIST_ALL),
    takeEvery(actions.GET_BY_ID, GET_BY_ID),
    // takeEvery(actions.POST_CREATE,POST_CREATE),
    // takeEvery(actions.POST_UPDATE,POST_UPDATE),
    // takeEvery(actions.DELETE_DATA,DELETE_DATA),
  ])
}

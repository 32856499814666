import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  usersPaginate,
  usersUpdate,
  usersSummary,
  usersAdminPaginate,
  usersUpdateStatus,
} from 'services/users'
import actions from './actions'

export function* GET_USERS_ADMIN_PAGINATE() {
  yield loading()
  const { listTableUsersAdmin } = yield select(state => state.users)
  const success = yield call(usersAdminPaginate, listTableUsersAdmin.paginate)
  if (success) {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        listTableUsersAdmin: {
          data: success.data,
          paginate: listTableUsersAdmin.paginate,
        },
      },
    })
  }
  yield loaded()
}

export function* GET_PAGINATE() {
  yield loading()
  const { listTable } = yield select(state => state.users)
  const { activityUserSummary } = yield select(state => state.dashboard)
  const success = yield call(usersPaginate, {
    ...listTable.paginate,
    from: activityUserSummary.fromTo[0],
  })
  if (success) {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        listTable: {
          data: success.data,
          paginate: listTable.paginate,
        },
      },
    })
  }
  yield loaded()
}

export function* UPDATE_STATUS({ payload }) {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loadingStatus: true,
    },
  })
  const success = yield call(usersUpdate, payload)
  if (success) {
    yield put({
      type: 'users/GET_PAGINATE',
    })
  }
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loadingStatus: false,
    },
  })
}

export function* UPDATE({ payload }) {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loadingStatus: true,
    },
  })
  const success = yield call(usersUpdateStatus, payload)
  if (success) {
    yield put({
      type: 'users/GET_PAGINATE',
    })
  }
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loadingStatus: false,
    },
  })
}

export function* GET_SUMMARY() {
  yield loading()
  const { listTable } = yield select(state => state.users)
  const success = yield call(usersSummary, listTable.paginate)
  if (success) {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        summary: success,
      },
    })
  }
  yield loaded()
}

export function* loading() {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
    takeEvery(actions.UPDATE_STATUS, UPDATE_STATUS),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.GET_SUMMARY, GET_SUMMARY),
    takeEvery(actions.GET_USERS_ADMIN_PAGINATE, GET_USERS_ADMIN_PAGINATE),
  ])
}

export default function video() {
  return {
    forms: {
      item: [
        {
          name: 'id',
          hidden: true,
          rules: [{ required: false }],
          children: {},
        },
        {
          label: 'Judul Video',
          name: 'cms_video_name',
          rules: [{ required: true }],
          className: 'col-12',
          children: {
            component: 'input',
            type: 'text',
            placeholder: 'Ketik Judul Video',
          },
        },
        {
          label: 'Urutan Video',
          name: 'cms_video_order',
          rules: [{ required: true }],
          className: 'col-6',
          children: {
            component: 'input',
            type: 'text',
            placeholder: 'Ketik Urutan Video',
          },
        },
        {
          label: 'Deskripsi',
          name: 'cms_video_description',
          rules: [{ required: true }],
          className: 'col-12',
          children: {
            component: 'textarea',
            type: 'text',
            placeholder: 'Ketik Deskripsi',
          },
        },
        {
          label: 'Video URL',
          name: 'cms_video_video_url',
          rules: [{ required: true }],
          className: 'col-6',
          children: {
            component: 'input',
            type: 'text',
            placeholder: 'https://youtube.com',
          },
        },
        {
          label: 'Status Aktif',
          name: 'cms_video_is_active',
          rules: [{ required: true }],
          className: 'col-12',
          children: {
            component: 'radio',
            options: [
              { label: 'Ya', value: true },
              { label: 'Tidak', value: false },
            ],
            optionType: 'button',
            buttonStyle: 'solid',
          },
        },
      ],
      fields: [
        { name: ['id'] },
        { name: ['cms_video_name'] },
        { name: ['cms_video_order'] },
        { name: ['cms_video_description'] },
        { name: ['cms_video_video_url'] },
        { name: ['cms_video_is_active'] },
      ],
    },
  }
}

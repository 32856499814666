export default function ebook() {
  return {
    forms: {
      item: [
        {
          name: 'id',
          hidden: true,
          rules: [{ required: false }],
          children: {},
        },
        {
          label: 'Judul E-Book',
          name: 'cms_ebook_name',
          rules: [{ required: true }],
          className: 'col-12',
          children: {
            component: 'input',
            placeholder: 'Ketik Judul E-Book',
          },
        },
        {
          name: ['cmsEbookCategory','id'],
          rules: [{ required: true }],
          className: 'col-12',
          children: {
            component: 'selectCommon',
            placeholder: 'Pilih kategori',
            data: 'cms_ebook_category',
          },
        },
        {
          label: 'Cover E-Book',
          name: 'cms_ebook_cover_url',
          rules: [{ required: true }],
          className: 'col-12',
          children: {
            component: 'uploadCustom',
            placeholder: 'Upload Cover E-Book',
            url:'ebook'
          },
        },
        {
          label: 'Upload File E-Book',
          name: 'cms_ebook_file_url',
          rules: [{ required: true }],
          className: 'col-12',
          help: 'Khusus PDF, maks 8mb',
          children: {
            component: 'uploadCustom',
            placeholder: 'Ketik Sub Materi',
            url:'ebook'
          },
        },
      ],
      fields: [
        {
          name: ['id'],
        },
        {
          name: ['cms_ebook_cover_url'],
        },
        {
          name: ['cms_ebook_file_url'],
        },
        {
          name: ['cms_ebook_name'],
        },
        {
          name: ['cmsEbookCategory','id'],
        },
      ],
    },
  }
}

import apiClient from 'services/axios'
import response from 'services/response'

export async function packetPaginate(payload) {
  const { perPage, currentPage, sort, search, status } = payload
  let res = null
  const params = search ? `/${search}` : ''
  try {
    res = await apiClient.get(`master/packet/${status}/${perPage}/${currentPage}/${sort}${params}`)
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function packetListAll() {
  let res = null
  try {
    res = await apiClient.get(`master/packet/list-all`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function packetListAllPublish() {
  let res = null
  try {
    res = await apiClient.get(`master/packet/list-all-publish`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function packetCreate(payload) {
  let res = null
  try {
    res = apiClient.post(`master/packet`, payload)
    return res.data
  } catch (error) {
    return false
  }
}

export async function packetUpdate(payload) {
  const { packetId, status } = payload
  let res = null
  try {
    res = await apiClient.post(`master/packet/publish/${packetId}/${status}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function packetFindById({ id }) {
  let res = null
  try {
    res = await apiClient.get(`master/packet/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export async function packetDelete(id) {
  let res = null
  try {
    res = apiClient.delete(`master/packet/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

import apiClient from 'services/axios'
import response from 'services/response'

export async function cmsPaginate(payload) {
  const { perPage, currentPage, sort, search, suffix } = payload
  let res = null
  const params = search ? `/${search}` : ''
  const cId = payload?.categoryId ? `/${payload?.categoryId}` : ''
  try {
    res = await apiClient.get(
      `master/cms-${suffix}/${perPage}/${currentPage}/${sort}${cId}${params}`,
    )
    return response({ ...res.data, search, sort })
  } catch (error) {
    return false
  }
}

export async function cmsListAll(payload) {
  try {
    const res = await apiClient.get(`master/cms-${payload}/list-all`)
    return res.data.data
  } catch (error) {
    return false
  }
}

export async function cmsStore(payload) {
  let res = null
  const { id, data, suffix } = payload
  const url = `master/cms-${suffix}${id ? `/${id}` : ''}`
  try {
    res = await apiClient.post(url, data)
    return res.data
  } catch (error) {
    return false
  }
}

export async function cmsDelete(payload) {
  const { suffix, id } = payload
  let res = null
  try {
    res = await apiClient.delete(`master/cms-${suffix}/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  excercisePaginate,
  excerciseListAll,
  excerciseStore,
  // excerciseUpdate,
  // excerciseFindById,
  // excerciseDelete,
} from 'services/excercise'
import actions from './actions'

export function* GET_PAGINATE() {
  const { listTable } = yield select(state => state.excercise)
  const { id } = yield select(state => state.packet)
  yield loading()
  const success = yield call(excercisePaginate, { ...listTable.paginate, packetId: id })
  if (success) {
    yield put({
      type: 'excercise/SET_STATE',
      payload: {
        listTable: {
          data: success.data.sort((a, b) => a.created_at.localeCompare(b.created_at)),
          paginate: {
            ...listTable.paginate,
            ...success.paginate,
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_LIST_ALL() {
  const success = yield call(excerciseListAll)
  if (success) {
    yield put({
      type: 'excercise/SET_STATE',
      payload: {
        listAll: success,
      },
    })
  }
}

export function* GET_BY_ID({ payload }) {
  yield put({
    type: 'excercise/SET_STATE',
    payload,
  })
}

export function* POST_STORE({ payload }) {
  yield loading()
  const success = yield call(excerciseStore, payload)
  const { data } = yield select(state => state.excercise)
  if (success) {
    yield put({
      type: 'excercise/SET_STATE',
      payload: {
        data: {
          ...success,
          material: data.material,
        },
        id: success.id,
      },
    })
  }
  yield loaded()
  yield put({
    type: 'excercise/GET_PAGINATE',
  })
}

export function* loading() {
  yield put({
    type: 'excercise/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'excercise/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.SET_STATE,SET_STATE),
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
    takeEvery(actions.GET_LIST_ALL, GET_LIST_ALL),
    takeEvery(actions.GET_BY_ID, GET_BY_ID),
    takeEvery(actions.POST_STORE, POST_STORE),
    // takeEvery(actions.POST_UPDATE,POST_UPDATE),
    // takeEvery(actions.DELETE_DATA,DELETE_DATA),
  ])
}

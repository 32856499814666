import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  packetPaginate,
  packetListAll,
  packetListAllPublish,
  // packetCreate,
  packetUpdate,
  packetFindById,
  // packetDelete,
} from 'services/packet'
import actions from './actions'

export function* GET_PAGINATE({ payload }) {
  yield loading()
  const packet = yield select(state => state.packet)
  const { listTable } = yield select(state => state.packet[payload])
  const success = yield call(packetPaginate, {
    ...listTable.paginate,
    status: payload === 'terbit',
  })
  if (success) {
    yield put({
      type: 'packet/SET_STATE',
      payload: {
        ...packet,
        [payload]: {
          ...packet[payload],
          listTable: {
            data: success.data,
            paginate: {
              ...listTable.paginate,
              ...success.paginate,
            },
          },
        },
      },
    })
  }
  yield loaded()
}

export function* GET_LIST_ALL() {
  const success = yield call(packetListAll)
  if (success) {
    yield put({
      type: 'packet/SET_STATE',
      payload: {
        listAll: success,
      },
    })
  }
}

export function* GET_LIST_ALL_PUBLISH() {
  yield loading()
  const success = yield call(packetListAllPublish)
  if (success) {
    yield put({
      type: 'packet/SET_STATE',
      payload: {
        listAll: success,
      },
    })
  }
  yield loaded()
}

export function* POST_UPDATE({ payload }) {
  yield loading()
  const success = yield call(packetUpdate, payload)
  if (success) {
    const packet = yield select(state => state.packet)
    const { listTable } = yield select(state => state.packet.konsep)
    const konsep = yield call(packetPaginate, { ...listTable.paginate, status: false })
    if (konsep) {
      yield put({
        type: 'packet/SET_STATE',
        payload: {
          ...packet,
          konsep: {
            ...packet.konsep,
            listTable: konsep,
          },
        },
      })
    }
  }
  yield loaded()
}

export function* GET_BY_ID({ payload }) {
  yield put({
    type: 'packet/SET_STATE',
    payload: {
      ...payload,
      visible: true,
    },
  })
  yield loading()
  const success = yield call(packetFindById, payload)
  if (success) {
    yield put({
      type: 'packet/SET_STATE',
      payload: {
        detail: success,
      },
    })
    yield put({
      type: 'excercise/GET_PAGINATE',
    })
  }
  yield loaded()
}

export function* loading() {
  yield put({
    type: 'packet/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'packet/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.SET_STATE,SET_STATE),
    takeEvery(actions.GET_PAGINATE, GET_PAGINATE),
    takeEvery(actions.GET_LIST_ALL, GET_LIST_ALL),
    takeEvery(actions.GET_LIST_ALL_PUBLISH, GET_LIST_ALL_PUBLISH),
    takeEvery(actions.GET_BY_ID, GET_BY_ID),
    // takeEvery(actions.POST_CREATE,POST_CREATE),
    takeEvery(actions.POST_UPDATE, POST_UPDATE),
    // takeEvery(actions.DELETE_DATA,DELETE_DATA),
  ])
}
